const Blue = '#21242e';
const Orange = '#FFBA60';
const lightBlue = `#1976D2`;
const Grey = '#616161';

const generateStylesWrapper = (theme) => {
	return {
		palette: {
			common: {
				Blue: `${Blue}`,
				Orange: `${Orange}`,
				Gray: `${Grey}`,
			},
			primary: {
				main: `${Blue}`,
				contrastText: '#fff',
			},
			secondary: {
				main: `${lightBlue}`,
				contrastText: '#fff',
			},
		},
		typography: {
			shipmentHeading: {
				fontFamily: 'Raleway, Sans-serif',
				fontSize: '50px',
				fontWeight: '700',
			},
			tab: {
				fontFamily: 'Raleway, Sans-serif',
				textTransform: 'none',
				fontWeight: 700,
				fontSize: '1rem',
			},
			estimate: {
				fontFamily: 'Raleway, Sans-serif',
				fontSize: '1rem',
				textTransform: 'none',
			},
			h2: {
				fontFamily: 'Raleway, Sans-serif',
				fontSize: '20px',
				fontWeight: '700',
			},
			h3: {
				fontFamily: 'Raleway, Sans-serif',
				fontSize: '14px',
				lineHeight: 1,
			},
			h4: {
				fontFamily: 'Raleway, Sans-serif',
				fontSize: '1.75rem',
				fontWeight: 700,
			},
			h5: {
				fontFamily: 'Raleway, Sans-serif',
				fontSize: '20px',
			},
			h6: {
				fontFamily: 'Raleway, Sans-serif',
				fontSize: '14px',
				fontWeight: 700,
				lineHeight: 1,
			},
			subtitle1: {
				fontSize: '1.25rem',
				fontWeight: 300,
			},
			subtitle2: {
				fontFamily: 'Raleway, sans-serif',
				fontSize: '.9rem',
				fontWeight: 300,
			},

			body1: {
				fontFamily: 'Raleway, sans-serif',
				fontSize: '1.25rem',
				fontWeight: 600,
			},
		},
		background: {
			background: '#fbfbfb',
			margin: 0,
			borderRadius: 0,
			flexDirection: 'column',
			alignItems: 'center',
		},
		Paper: {
			maxWidth: '1200px',
			//marginTop: "0.5em",
		},
		flexChangeOnResponsive: {
			flexDirection: 'column',
			[theme.breakpoints.up('sm')]: {
				flexDirection: 'row',
			},
		},
		marginRightOnResponsive: {
			[theme.breakpoints.up('sm')]: {
				paddingRight: '1em',
			},
		},
		Heading: {
			minHeight: '5em',
			fontSize: '1.3em',
			fontFamily: 'Raleway, sans-serif',
			// background: "#C1272D",
			alignItems: 'center',
			flexDirection: 'column',
			padding: '1em',
		},
		inputContainer: {
			fontSize: '1.3em',
			fontFamily: 'Raleway, sans-serif',
			background: '#313f4c',
			padding: '1em',
			[theme.breakpoints.up('sm')]: {
				paddingBottom: '2em',
			},
		},
		Link: { textDecoration: 'none' },
		Button: {
			borderRadius: '20px',
			padding: '13px',
			backgroundColor: `${theme.palette.common.Blue}`,
			'&:hover': {
				backgroundColor: theme.palette.secondary.light,
			},
		},
		stepper: {
			width: '100%',
			background: 'rgb(205, 52, 43)',
			background: 'linear-gradient(90deg, rgba(205,52,43,1) 0%, rgba(240,89,36,1) 100%)',
			borderRadius: '10px',
			boxSizing: 'border-box',
			'& .MuiStepLabel-label': {
				fontWeight: '700',
			},
			[theme.breakpoints.down('sm')]: {
				flexDirection: 'column',
				alignItems: 'center',
				'& .MuiStep-root': {
					marginBottom: '15px',
				},
				'& .MuiStepConnector-line': {
					display: 'none',
				},
			},
		},
		StepperPaper: {
			padding: '30px',
			borderRadius: '10px',
			background: '#fff',
			minHeight: '50vh',
			justifyContent: 'flexStart !important',
		},
	};
};

const generateStylesCalculateCargo = (theme) => {
	return {
		tabs: {
			fontSize: '1em',
			fontFamily: 'Raleway, sans-serif',
			fontWeight: 700,
			background: '#f2f2f2',
			height: 'calc(100% - 10px)',
			// borderRight: `1px solid ${theme.palette.divider}`,
			color: `${theme.palette.common.Blue}`,
			paddingTop: '5px',
			paddingBottom: '5px',
			borderRadius: '10px',
			'& .root': {
				fontFamily: 'Raleway, sans-serif',
			},
			'& .MuiTab-wrapper': {
				padding: '10px',
				paddingLeft: '20px',
				paddingRight: '20px',
				alignItems: 'center',
				flexDirection: 'row',
				textTransform: 'capitalize',

				justifyContent: 'flexStart !important',
			},
			'& .Mui-selected': {
				border: '0px',
				'& .MuiTab-wrapper': {
					borderRadius: '10px',
					backgroundColor: 'white',
					fontWeight: 'bold',
				},
			},
			'& .MuiSvgIcon-root': {
				height: '0.8em',
				width: '0.8em',
				marginRight: '10px',
				marginBottom: '0px !important',
			},
			'& .MuiTab-labelIcon': {
				minHeight: 'auto',
			},
			'& .MuiButtonBase-root': {
				padding: '5px',
				paddingTop: '5px',
				paddingBottom: '0px',
				maxWidth: 'none',
			},
			'& .MuiButtonBase-root:hover': {
				'& .MuiTab-wrapper': {
					borderRadius: '10px',
					backgroundColor: '#d9d9d9',
					fontWeight: 'bold',
					color: 'black',
				},
			},
		},
	};
};

const generateStylesInformation = (theme) => {
	return {
		flexChangeOnResponsive: {
			flexDirection: 'column',
			[theme.breakpoints.up('sm')]: {
				flexDirection: 'row',
			},
		},
		marginRightOnResponsive: {
			[theme.breakpoints.up('sm')]: {
				paddingRight: '1em',
			},
		},
		inputContainer: {
			fontSize: '1.3em',
			fontFamily: 'Raleway, sans-serif',
			// background: "#313f4c",
			[theme.breakpoints.up('sm')]: {
				padding: '1em',
				paddingBottom: '2em',
			},
		},
	};
};

const generateStylesAppointment = (theme) => {
	return {
		datePickerStyles: {
			[theme.breakpoints.down('sm')]: {
				'& .MuiPickersStaticWrapper-staticWrapperRoot': {
					minWidth: 'auto',
				},
			},
		},
	};
};

export { generateStylesWrapper, generateStylesInformation, generateStylesCalculateCargo, generateStylesAppointment };
