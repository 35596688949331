import React, { useEffect } from "react";
import { Outlet } from "react-router-dom";
import "./Styles/style.css";
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import useMediaQuery from "@material-ui/core/useMediaQuery";
import CssBaseline from "@material-ui/core/CssBaseline";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import IconButton from "@material-ui/core/IconButton";
import Badge from "@material-ui/core/Badge";
import Container from "@material-ui/core/Container";
import Link from "@material-ui/core/Link";
import MenuIcon from "@material-ui/icons/Menu";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import NotificationsIcon from "@material-ui/icons/Notifications";
import MainListItems from "./MainListItems";
import Button from "@material-ui/core/Button";
import Avatar from "@material-ui/core/Avatar";
import Cookies from "universal-cookie";
import { Scrollbars } from "react-custom-scrollbars";

// import instance from '../../../Config/Axios/instance';
// import drawerBg from '../../../Assets/drawer_bg_pic.png';
// import dealmateLogo from '../../../Assets/dealmate_logo.png';
const cookies = new Cookies();

function Copyright() {
  return (
    <Typography variant="body2" align="center" style={{ color: "white" }}>
      {"Copyright © "}
      <Link color="inherit" href="https://material-ui.com/">
        PMC
      </Link>{" "}
      {/* {new Date().getFullYear()} */}
    </Typography>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: "0 8px",
    ...theme.mixins.toolbar,
  },
  appBar: {
    height: "60px",
    background: "#f6f6f6", //theme.palette.secondar
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: "none",
  },
  title: {
    flexGrow: 1,
    fontSize: "0.875em",
  },
  username: {
    fontSize: "0.875em",
    marginLeft: "20px",
    marginRight: "10px",
  },
  drawerPaper: {
    backgroundColor: `#21242e`,
    // backgroundImage: 'url(' + '' + ')',
    backgroundRepeat: "no-repeat",
    backgroundSize: "cover",
    position: "relative",
    whiteSpace: "nowrap",
    width: drawerWidth,
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    display: { xs: "block", sm: "none" },
  },
  drawerPaperClose: {
    overflowX: "hidden",
    transition: theme.transitions.create("width", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: 0,
    [theme.breakpoints.up("sm")]: {
      width: 0,
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: "100vh",
    overflow: "auto",
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    backgroundColor: "#fafafa",
  },
  paper: {
    padding: theme.spacing(2),
    display: "flex",
    overflow: "auto",
    flexDirection: "column",
  },
  fixedHeight: {
    height: 240,
  },
  small: {
    padding: "2px",
    background: "black",
    width: theme.spacing(3),
    height: theme.spacing(3),
  },
}));

export default function Dashboard() {
  const classes = useStyles();
  const matches = useMediaQuery("(min-width:780px)");
  const [open, setOpen] = React.useState(true);
  const [userName, setUserName] = React.useState("Hi Admin");
  const [userAvatar, setUserAvatar] = React.useState();
  // const location = useLocation();
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  const logout = () => {
    cookies.remove("token", { path: "/" });
    cookies.remove("userData", { path: "/" });
    window.location.href = "/";
  };

  useEffect(() => {
    console.log(matches, "matched");
    if (matches) {
      handleDrawerOpen();
    } else {
      handleDrawerClose();
    }
  }, [matches]);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden
            )}
          >
            <MenuIcon />
          </IconButton>
          <Typography color="primary" noWrap className={classes.title}>
            PMC Admin
          </Typography>

          {/* <IconButton color="primary" fontSize="small">
            <Badge badgeContent={4} color="secondary">
              <NotificationsIcon fontSize="small" />
            </Badge>
          </IconButton> */}
          <React.Fragment>
            <Typography
              className={classes.username}
              color="secondary"
              variant="button"
            >
              {userName}
            </Typography>
            {/* <Avatar alt="Seller Avatar" src={userAvatar} /> */}
          </React.Fragment>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
        ModalProps={{
          keepMounted: true, // Better open performance on mobile.
        }}
        // sx={{
        //   display: { xs: "block", sm: "none" },
        //   "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        // }}
        onClose={handleDrawerClose}
      >
        <div className={classes.toolbarIcon}>
          {/* <Avatar alt='Dealmate' src={dealmateLogo} className={classes.small} /> */}

          <Typography
            variant="h4"
            style={{ color: "#f6f6f6", textAlign: "center" }}
          >
            PMC
          </Typography>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon style={{ color: "#f6f6f6" }} />
          </IconButton>
        </div>
        <Divider
          variant="middle"
          style={{ backgroundColor: "#f6f6f6", margin: "0" }}
        />
        <Scrollbars style={{ margin: "0 0 10px" }}>
          <List style={{ padding: ".5em" }} dense>
            <MainListItems drawerOpen={open} />
          </List>
        </Scrollbars>

        <Divider />
        <div
          style={{
            margin: "10px",
            width: "calc(100% - 20px)",
            // position: "absolute",
            // bottom: "10px",
          }}
        >
          {open ? (
            <>
              <Button
                onClick={logout}
                variant="contained"
                fullWidth
                style={{
                  background: "#a70000",
                  color: "white",
                }}
              >
                Logout
              </Button>
              <Divider />
              <Box pt={2}>
                <Copyright />
              </Box>
            </>
          ) : null}
        </div>
      </Drawer>
      {/* <Drawer
        variant="permanent"
        sx={{
          display: { xs: "none", sm: "block" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: drawerWidth },
        }}
        open
      >
        <h1>hllo</h1>
      </Drawer> */}
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Outlet />
        </Container>
      </main>
    </div>
  );
}
