import {
  Button,
  Fade,
  Grid,
  makeStyles,
  Paper,
  Snackbar,
  Typography,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import React, { useEffect, useState } from "react";
import Header from "../CalculateVolumeStepper/Header";
import Footer from "../CalculateVolumeStepper/Footer";
import clsx from "clsx";
import { generateStylesWrapper } from "../CalculateVolumeStepper/Helpers/StylesGenerator";
import CalculateCargo from "../CalculateVolumeStepper/Steps/calculateCargo";

export default function VolumeCalculator() {
  const [value, setValue] = React.useState(0);
  const [selectedItems, setSelectedItems] = React.useState([]);
  const [selectedItemsVolume, setSelectedItemsVolume] = React.useState(0);
  const [openSanckbar, setOpenSnackbar] = useState({
    open: false,
    severity: "",
  });

  const useStyle = makeStyles((theme) => generateStylesWrapper(theme));

  const classes = useStyle();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleSetSelectedItems = (item) => {
    const isItemPresent = selectedItems.find((x) => x._id === item._id);

    // if item is not present
    if (!isItemPresent) {
      setSelectedItems([...selectedItems, item]);
    } else {
      const updatedItems = selectedItems.map((x) => {
        if (x._id === item._id) {
          return item;
        }
        return x;
      });
      setSelectedItems([...updatedItems]);
    }
  };

  const handleDeleteSelectedItems = (_id) => {
    const filterItems = selectedItems.filter((x) => x._id !== _id);
    setSelectedItems([...filterItems]);
  };

  useEffect(() => {
    const volume = selectedItems.length
      ? selectedItems.reduce(
          (acc, item) => {
            return { x: acc.x + item.volume * item.quantity };
          },
          { x: 0 }
        )
      : { x: 0 };
    setSelectedItemsVolume(volume.x);
  }, [selectedItems]);
  return (
    <>
      <Grid
        item
        container
        className={classes.background}
        justifyContent="center"
      >
        <Header />
        <Grid
          item
          container
          justifyContent="center"
          style={{ textAlign: "center" }}
          className={classes.Heading}
        >
          <Typography variant="h4" gutterBottom>
            Book your moving service online today!
          </Typography>
          <Typography variant="body2">
            Please fill out the details of your move below, and you will be
            provided with a non-binding moving offer.
          </Typography>
          <br />
          <Button
            style={{ background: "#f3541e", color: "white" }}
            onClick={() => {
              window.open(
                "https://offer.professionalmovingcompany.nl/",
                "_blank"
              );
            }}
          >
            Get Offer Now
          </Button>
        </Grid>
        <Grid
          item
          container
          component={Paper}
          className={clsx(classes.Paper, classes.StepperPaper)}
          elevation={0}
          square
          justifyContent="flex-start"
          direction="column"
          alignItems="center"
        >
          <CalculateCargo
            handlers={{
              value,
              handleChange,
              selectedItems,
              handleSetSelectedItems,
              setOpenSnackbar,
              handleDeleteSelectedItems,
              selectedItemsVolume,
            }}
          />
        </Grid>

        <Snackbar
          open={openSanckbar.open}
          autoHideDuration={6000}
          TransitionComponent={Fade}
          onClose={() => {
            setOpenSnackbar({
              open: false,
              message: "",
              severity: openSanckbar.severity,
            });
          }}
        >
          <Alert
            onClose={() => {
              setOpenSnackbar({
                open: false,
                message: "",
                severity: openSanckbar.severity,
              });
            }}
            severity={openSanckbar.severity}
          >
            {openSanckbar.message}
          </Alert>
        </Snackbar>
        <Footer />
      </Grid>
    </>
  );
}
