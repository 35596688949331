import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
	Button,
	Fade,
	Grid,
	Paper,
	Snackbar,
	FormControl,
	InputLabel,
	TextField,
	Typography,
	Tabs,
	Tab,
	Box,
	OutlinedInput,
	IconButton,
	InputAdornment,
	Divider,
	Radio,
	RadioGroup,
	FormControlLabel,
	FormLabel,
	Card,
	CardContent,
	CardMedia,
	List,
	ListItem,
	ListItemAvatar,
	ListItemSecondaryAction,
	ListItemText,
	Avatar,
	Stepper,
	Step,
	StepLabel,
	StepButton,
} from '@material-ui/core';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import DeleteIcon from '@material-ui/icons/Delete';
import FolderIcon from '@material-ui/icons/Folder';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import { useNavigate, useLocation } from 'react-router-dom';
import MuiAlert from '@material-ui/lab/Alert';
import MapDialog from './MapDialog';
import axios from 'axios';
import Header from './Header';
import NavBar from './NavBar';
import Footer from './Footer';
import bgImg from '../../asset/image/asd-min.jpg';
import truckImg from '../../asset/image/moving.jpg';
import cookerImg from '../../asset/image/Cooker.png';
import ovenImg from '../../asset/image/Oven.png';
import microwaveImg from '../../asset/image/Microwave.png';
import equippedKitchenImg from '../../asset/image/EquippedKitchen.png';
import tvImg from '../../asset/image/MeubleTV.png';
import bookShelveImg from '../../asset/image/Bibliotheque.png';
import chairImg from '../../asset/image/Chaise.png';
import pianoImg from '../../asset/image/Piano.png';
import PhoneIcon from '@material-ui/icons/Phone';
import InformationStep from './Steps/Information';
import CalculateCargo from './Steps/calculateCargo';
import Review from './Steps/Review';
import Appointment from './Steps/Appointment';
import TabPanel from './TabPanel';
import Spacer from './Helpers/Spacer';
import Alert from './Helpers/Alert';
import { generateStylesWrapper } from './Helpers/StylesGenerator';
import { orderCalculations } from './Helpers/OrderCalculations';
import swal from 'sweetalert';
import CustomButton from '../ui/CustomButton';

const moment = require('moment');

function a11yProps(index) {
	return {
		id: `vertical-tab-${index}`,
		'aria-controls': `vertical-tabpanel-${index}`,
	};
}

const useStyle = makeStyles((theme) => generateStylesWrapper(theme));

function CalculateVolume() {
	let location = useLocation();
	const nameQueryValue = new URLSearchParams(location.search).get('name');
	const emailQueryValue = new URLSearchParams(location.search).get('email');
	const mobileQueryValue = new URLSearchParams(location.search).get('mobile');
	const classes = useStyle();
	let navigate = useNavigate();
	const matchesSm = useMediaQuery('(min-width:752px)');

	// *************************** State ***************************
	const [open, setOpen] = React.useState(false);
	const [openDelivery, setOpenDelivery] = React.useState(false);
	const [selectedPickupValue, setSelectedPickupValue] = React.useState();
	const [selectedPickupLocation, setSelectedPickupLocation] = React.useState();
	const [selectedDeliveryValue, setSelectedDeliveryValue] = React.useState();
	const [selectedDeliveryLocation, setSelectedDeliveryLocation] = React.useState();
	const [selectedDeliveryAddress, setSelectedDeliveryAddress] = React.useState();
	const [selectedPickupAddress, setSelectedPickupAddress] = React.useState('');
	const [addressDistance, setAddressDistance] = React.useState(0);
	const [selectedItems, setSelectedItems] = React.useState([]);
	const [selectedItemsVolume, setSelectedItemsVolume] = React.useState(0);
	const [value, setValue] = React.useState(0);
	const [hoCoords, setHoCoords] = React.useState({
		lat: 52.099837716589384,
		lng: 4.280881931567835,
	});
	const [hoToFirstAddress, setHoToFirstAddress] = React.useState(0);
	const [secondAddressToHo, setSecondAddressToHo] = React.useState(0);
	console.log(hoToFirstAddress, 'hoToFirstAddress');
	console.log(secondAddressToHo, 'secondAddressToHo');
	console.log(addressDistance, 'addressDistance');
	// stepper
	const [activeStep, setActiveStep] = React.useState(0);
	const steps = getSteps();
	const [date, changeDate] = useState(moment());
	const [selectedValue, setSelectedValue] = React.useState('');

	const [formData, setformData] = useState({
		name: '',
		email: '',
		mobile: '',
		cargo: 'no',
		cargoHours: '0',
		numberOfWorkers: 0,
		numberOfStairsFirstAddress: '0',
		numberOfStairsSecondAddress: '0',
	});
	const [validationHelper, setValidationHelper] = useState({
		nameHelper: '',
		emailHelper: '',
		mobileHelper: '',
		cargoHelper: '',
		cargoHoursHelper: '',
		numberOfWorkersHelper: '',
		numberOfStairsFirstAddressHelper: '',
		numberOfStairsSecondAddressHelper: '',
		pickupLocationHelper: '',
		deliveryLocationHelper: '',
	});
	const { name, email, mobile, cargo, cargoHours, numberOfWorkers, numberOfStairsFirstAddress, numberOfStairsSecondAddress } = formData;

	console.log(formData, 'formdata');

	const [openSanckbar, setOpenSnackbar] = useState({
		open: false,
		severity: '',
	});

	// *************************** HANDLERS ***************************
	const handleNext = () => {
		let { isError: formErr, errorMessage } = Validation();
		let itemsErr = itemsValidation();
		let timeSlotErr = timeSlotValidation();
		if (activeStep === 0) {
			let distanceExceeded = parseInt(addressDistance) / 1000 > 2000;
			if (!formErr && !distanceExceeded) {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			} else {
				let messageString;
				if (errorMessage && errorMessage.length > 0) {
					messageString = errorMessage?.reduce((ele, acc) => {
						return ele + ' \n ' + acc;
					});
				} else if (distanceExceeded) {
					messageString = 'Unfortunately we are not active in this region';
				} else {
					messageString = 'Error in validation';
				}
				swal('error', messageString);
			}
			return;
		}
		if (activeStep === 1) {
			if (!itemsErr) {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			} else {
				// alert("Select Items first!!!");
				swal('error', 'Select Items first!!!');
			}
			return;
		}
		if (activeStep === 2) {
			if (!timeSlotErr) {
				setActiveStep((prevActiveStep) => prevActiveStep + 1);
			} else {
				// alert("Select a time slot first!!!");
				swal('Select a time slot first!!!');
			}
			return;
		}
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	const handleSetSelectedItems = (item) => {
		const isItemPresent = selectedItems.find((x) => x._id === item._id);

		// if item is not present
		if (!isItemPresent) {
			setSelectedItems([...selectedItems, item]);
		} else {
			const updatedItems = selectedItems.map((x) => {
				if (x._id === item._id) {
					return item;
				}
				return x;
			});
			setSelectedItems([...updatedItems]);
		}
	};

	const handleDeleteSelectedItems = (_id) => {
		const filterItems = selectedItems.filter((x) => x._id !== _id);
		setSelectedItems([...filterItems]);
	};

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = (value) => {
		setOpen(false);
		setSelectedPickupValue(value);
	};

	const handleClickOpenDelivery = () => {
		setOpenDelivery(true);
	};

	const handleCloseDelivery = (value) => {
		setOpenDelivery(false);
		setSelectedDeliveryValue(value);
	};

	const handleMouseDown = (event) => {
		event.preventDefault();
	};

	const validationReset = () => {
		setValidationHelper({
			emailHelper: '',
			mobileHelper: '',
			cargoHelper: '',
			numberOfWorkersHelper: '',
			numberOfStairsFirstAddressHelper: '',
			numberOfStairsSecondAddressHelper: '',
			pickupLocationHelper: '',
			deliveryLocationHelper: '',
		});
	};

	// ********************************* ONCHANGE HANDLE ******************************
	const onChange = (e) => {
		setformData({ ...formData, [e.target.name]: e.target.value });
		let valid;
		// validationReset();
		switch (e.target.name) {
			// case "mobile":
			//   console.log("object");
			//   valid =
			//     /^((\+31)|(0031)|0)(\(0\)|)(\d{1,3})(\s|-|)(\d{8}|\d{4}\s\d{4}|\d{2}\s\d{2}\s\d{2}\s\d{2})$/.test(
			//       e.target.value
			//     );
			//   if (!valid) {
			//     setValidationHelper({
			//       ...validationHelper,
			//       mobileHelper: "Invalid Number",
			//     });
			//   } else {
			//     setValidationHelper({ ...validationHelper, mobileHelper: "" });
			//   }
			//   break;
			case 'mobile':
				console.log('object');
				valid = /^[0-9]*$/.test(e.target.value);
				if (!valid) {
					setValidationHelper({
						...validationHelper,
						mobileHelper: 'Invalid Number',
					});
				} else {
					setValidationHelper({ ...validationHelper, mobileHelper: '' });
				}
				break;
			case 'email':
				console.log('object');
				valid =
					/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
						e.target.value,
					);
				if (!valid) {
					setValidationHelper({
						...validationHelper,
						emailHelper: 'Invalid Email',
					});
				} else {
					setValidationHelper({ ...validationHelper, emailHelper: '' });
				}
				break;
			default:
				break;
		}
	};

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	// ***************************Validation**************************

	const Validation = () => {
		let isError = false;
		let errorMessage = [];

		if (!formData.name) {
			console.log('wrong form data');
			isError = true;
			errorMessage.push('Name not found');
		}
		if (!formData.mobile || !formData.email) {
			console.log('wrong form data');
			isError = true;
			errorMessage.push('Mobile not found');
		}
		if (!formData.email) {
			console.log('wrong form data');
			isError = true;
			errorMessage.push('Email not found');
		}

		if (!formData.numberOfWorkers || !formData.numberOfWorkers * 1 > 0) {
			console.log('wrong form data');
			isError = true;
			errorMessage.push('Number of Workers should be selected');
		}

		for (let property in validationHelper) {
			console.log(validationHelper[property], 'is validation error');
			if (validationHelper[property]) {
				errorMessage.push(validationHelper[property]);
				isError = true;
			}
		}

		if (formData.cargo === 'yes') {
			if (![1, 2, 3, 4].includes(formData.cargoHours)) {
				console.log('wrong cargo hours');
				isError = true;
				errorMessage.push('Cargo Hours not found');
			}
		}
		console.log(selectedPickupLocation, 'selectedPickupLocation');
		if (!selectedPickupLocation || Object.keys(selectedPickupLocation).length === 0) {
			console.log('object inside');
			isError = true;

			validationHelper.pickupLocationHelper = 'Pickup Location is Required';
		}
		console.log(selectedDeliveryLocation, 'selectedDeliveryLocation');

		if (!selectedDeliveryLocation || Object.keys(selectedDeliveryLocation).length === 0) {
			console.log('object inside 2');
			isError = true;

			validationHelper.deliveryLocationHelper = 'Delivery Location is Required';
		}

		setValidationHelper({
			...validationHelper,
		});
		console.log(errorMessage, 'error messages');

		return { isError, errorMessage };
	};

	const itemsValidation = () => {
		let isError = false;
		if (!selectedItems.length > 0) {
			isError = true;
		}

		return isError;
	};

	const timeSlotValidation = () => {
		let isError = false;
		if (!date || !selectedValue) {
			isError = true;
		}
		return isError;
	};

	// *******************************onSubmit ******************************************************
	const onSubmit = (e) => {
		// let err = Validation();
		// if (err) {
		//   e.preventDefault();
		//   dispatch(userLogin(formData));
		// }
	};

	// **************************** GEO LOCATION ****************************
	var rad = function (x) {
		return (x * Math.PI) / 180;
	};

	var getDistance = function (p1, p2) {
		var R = 6378137; // Earth’s mean radius in meter
		var dLat = rad(p2.lat - p1.lat);
		var dLong = rad(p2.lng - p1.lng);
		console.log(dLat, 'dlat');
		console.log(dLong, 'dlong');
		var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(rad(p1.lat)) * Math.cos(rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
		var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
		var d = R * c;
		return d; // returns the distance in meter
	};

	const fetchGeoLocation = async (lat, lng) => {
		try {
			const res = await axios.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${lng}&key=AIzaSyATHgFeUY0gt1jd8oJpWSr99fqayTEHzUY`);

			return res?.data?.results[0]?.formatted_address;
		} catch (err) {
			console.log(err, 'error from geo code');
		}
	};

	const updatePickupAddress = async (lat, lng) => {
		const result = await fetchGeoLocation(lat, lng);
		setSelectedPickupAddress(result);
	};

	const updateDeliveryAddress = async (lat, lng) => {
		const result = await fetchGeoLocation(lat, lng);
		setSelectedDeliveryAddress(result);
	};

	const calculatTotalDistance = () => {
		console.log('working');
		if (hoToFirstAddress && secondAddressToHo && addressDistance) {
			return hoToFirstAddress + secondAddressToHo + addressDistance;
		} else {
			alert('Wrong input parameters');
		}
	};

	const calculateStairs = () => {
		let stairsInFirst = 0;
		let stairsInSecond = 0;
		if (numberOfStairsFirstAddress != -1) {
			stairsInFirst = numberOfStairsFirstAddress;
		}
		if (numberOfStairsSecondAddress != -1) {
			stairsInSecond = numberOfStairsSecondAddress;
		}

		return stairsInFirst * 1 + stairsInSecond * 1;
	};

	const getAddressDistance = () => {
		// temporary google map bypass
		// let selectedPickupLocation = {
		//   lat: 52.09627230132462,
		//   lng: 4.276176069398402,
		// };
		// let selectedDeliveryLocation = {
		//   lat: 52.09506856808111,
		//   lng: 4.278971126801065,
		// };
		// setAddressDistance(
		//   getDistance(selectedPickupLocation, selectedDeliveryLocation)
		// );

		// setHoToFirstAddress(getDistance(hoCoords, selectedPickupLocation));

		// setSecondAddressToHo(getDistance(selectedDeliveryLocation, hoCoords));
		console.log({ selectedPickupLocation, selectedDeliveryLocation });
		if (selectedPickupLocation && selectedDeliveryLocation) {
			setAddressDistance(getDistance(selectedPickupLocation, selectedDeliveryLocation));

			setHoToFirstAddress(getDistance(hoCoords, selectedPickupLocation));

			setSecondAddressToHo(getDistance(selectedDeliveryLocation, hoCoords));
		}
	};

	function getSteps() {
		return ['Enter your Moving Address', 'Calculate your Cargo', 'Make an Appointment', 'Confirm Move'];
	}

	const StepThreeWrapper = ({ args }) => {
		const [orderCalculationsData, setOrderCalculationsData] = useState();

		const calculations = async () => {
			let calcs = await orderCalculations({ args });
			setOrderCalculationsData(calcs);
		};
		useEffect(() => {
			calculations();
		}, []);
		return (
			<>
				{orderCalculationsData ? (
					<Review
						handlers={{
							orderCalculations: orderCalculationsData,
							cargoHours,
							selectedPickupLocation,
							selectedDeliveryLocation,
							selectedPickupAddress,
							selectedDeliveryAddress,
							addressDistance: addressDistance,
							totalDistance: calculatTotalDistance(),
							totalStairs: calculateStairs() * 1,
							selectedItemsVolume: selectedItemsVolume * 1,
							selectedItems,
							formData,
							selectedDate: date,
							selectedTimeslot: selectedValue,
						}}
					/>
				) : (
					<h2>Calculating your order</h2>
				)}
			</>
		);
	};

	function getStepContent(step) {
		switch (step) {
			case 0:
				// return "Step 1: Select campaign settings...";
				return (
					<InformationStep
						handlers={{
							onChange,
							selectedPickupAddress,
							handleClickOpen,
							handleMouseDown,
							selectedPickupValue,
							open,
							handleClose,
							selectedDeliveryAddress,
							handleClickOpenDelivery,
							selectedDeliveryValue,
							openDelivery,
							handleCloseDelivery,
							setSelectedPickupLocation,
							setSelectedDeliveryLocation,
							setSelectedDeliveryAddress,
							setSelectedPickupAddress,
							formData: formData,
							validationHelper: validationHelper,
							onChange: onChange,
						}}
					/>
				);
			case 1:
				return (
					<CalculateCargo
						handlers={{
							value,
							handleChange,
							selectedItems,
							handleSetSelectedItems,
							setOpenSnackbar,
							handleDeleteSelectedItems,
							addressDistance,
							selectedItemsVolume,
						}}
					/>
				);
			case 2:
				return (
					<Appointment
						handlers={{
							date,
							changeDate,
							selectedValue,
							setSelectedValue,
						}}
					/>
				);
			case 3:
				// input for caluclations
				let args = {
					// addressDistance between first & second address
					addressDistance: addressDistance,
					// addressDistance: 31000,
					// total distance = hoCoords to 1st address + 1st to 2nd address + 2nd address to hoCoords
					totalDistance: calculatTotalDistance(),
					// totalDistance: 152000,
					// totalStairs = 1st address stairs + 2nd address stairs
					totalStairs: calculateStairs() * 1,
					// totalStairs: 2 * 1,
					// number of movers
					numberOfWorkers: numberOfWorkers * 1,
					// total cargo volume
					selectedItemsVolume: selectedItemsVolume * 1,
					cargoHours: cargoHours,
				};
				return (
					<StepThreeWrapper args={args} />
					// <Review
					//   handlers={{
					//     orderCalculations: orderCalculations({ args }),
					//     cargoHours,
					//     selectedPickupLocation,
					//     selectedDeliveryLocation,
					//     selectedPickupAddress,
					//     selectedDeliveryAddress,
					//     addressDistance: addressDistance,
					//     totalDistance: calculatTotalDistance(),
					//     totalStairs: calculateStairs() * 1,
					//     selectedItemsVolume: selectedItemsVolume * 1,
					//     selectedItems,
					//     formData,
					//     selectedDate: date,
					//     selectedTimeslot: selectedValue,
					//   }}
					// />
				);
			default:
				return <div>Wrong Step!!!</div>;
		}
	}
	// **************************** USE EFFECTS ****************************
	useEffect(() => {
		if (selectedPickupLocation) {
			// const { lat, lng } = selectedPickupValue;
			// updatePickupAddress(lat, lng);
			getAddressDistance();
			setValidationHelper({ ...validationHelper, pickupLocationHelper: '' });
		}
	}, [selectedPickupLocation]);

	useEffect(() => {
		if (selectedDeliveryLocation) {
			// const { lat, lng } = selectedDeliveryValue;
			// updateDeliveryAddress(lat, lng);
			getAddressDistance();
			setValidationHelper({ ...validationHelper, deliveryLocationHelper: '' });
		}
	}, [selectedDeliveryLocation]);

	useEffect(() => {
		const volume = selectedItems.length
			? selectedItems.reduce(
					(acc, item) => {
						return { x: acc.x + item.volume * item.quantity };
					},
					{ x: 0 },
			  )
			: { x: 0 };
		setSelectedItemsVolume(volume.x);
	}, [selectedItems]);

	useEffect(() => {
		if (numberOfStairsFirstAddress == -1 || numberOfStairsSecondAddress == -1) {
			setformData({ ...formData, cargo: 'yes' });
		} else {
			setformData({ ...formData, cargo: 'no', cargoHours: '0' });
		}
	}, [numberOfStairsFirstAddress, numberOfStairsSecondAddress]);

	useEffect(() => {
		console.log({
			emailQueryValue,
			mobileQueryValue,
			nameQueryValue,
		});
		setformData({
			...formData,
			email: emailQueryValue ?? '',
			mobile: mobileQueryValue ?? '',
			name: nameQueryValue ?? '',
		});
	}, []);

	// useEffect(() => {
	//   var iw = document.getElementById("f_iframe_window").contentWindow;
	//   console.log(iw, "iw");
	//   iw?.document.getElementsByClassName("whb-header");
	//   console.log(iw?.document.getElementsByClassName("whb-header")[0]);
	// }, []);

	const iframeContent = () => {
		var iw = document.getElementById('f_iframe_window').contentWindow;
		console.log(iw, 'iw');
		iw?.document.getElementsByClassName('whb-header');
		console.log(iw?.document.getElementsByClassName('whb-header')[0]);
	};
	// **************************** JSX ****************************
	return (
		<Grid
			item
			container
			className={classes.background}
			justifyContent='center'>
			<Header />
			{/* <NavBar /> */}
			<div
			// style={{
			//   width: "100%",
			//   height: "700px",
			//   backgroundImage: `url(${truckImg})`,
			//   backgroundRepeat: "no-repeat",
			//   backgroundSize: "cover",
			// }}
			>
				{/* <img src={truckImg} /> */}
			</div>
			{/* <div class="h_iframe">
        <iframe
          src="https://professionalmovingcompany.nl/volume-calculator"
          frameborder="0"
          scrolling="no"
          height={500}
        >
        </iframe>
      </div> */}
			<Grid
				item
				container
				justifyContent='center'
				style={{ textAlign: 'center' }}
				className={classes.Heading}>
				<Typography
					variant='h4'
					gutterBottom>
					Book your moving service online today!
				</Typography>
				<Typography variant='body2'>Please fill out the details of your move below, and you will be provided with a non-binding moving offer.</Typography>
			</Grid>
			<Grid
				item
				container
				component={Paper}
				className={clsx(classes.Paper, classes.StepperPaper)}
				elevation={0}
				square
				justifyContent='flex-start'
				direction='column'
				alignItems='center'
				style={{ paddingBottom: '2rem' }}>
				<Stepper
					activeStep={activeStep}
					className={classes.stepper}
					alternativeLabel>
					{steps.map((label) => (
						<Step key={label}>
							<StepLabel>{label}</StepLabel>
						</Step>
					))}
				</Stepper>
				<div
					style={{
						marginTop: '30px',
						display: 'flex',
						width: '100%',
						flexDirection: 'column',
						// justifyContent: "space-between",
						flex: 1,
					}}>
					{activeStep === steps.length ? (
						<div>
							<Typography className={classes.instructions}>All steps completed</Typography>
							<Button onClick={handleReset}>Reset</Button>
						</div>
					) : (
						<div
							style={{
								display: 'flex',
								flexDirection: 'column',
								justifyContent: 'space-between',
								flex: 1,
							}}>
							{getStepContent(activeStep)}
							{/* <Typography className={classes.instructions}></Typography> */}
							<div
								style={{
									display: 'flex',
									justifyContent: 'center',
									marginTop: '30px',
								}}>
								{activeStep != 0 && (
									<CustomButton
										id={`btn-back-${activeStep + 1}`}
										disabled={activeStep === 0}
										onClick={handleBack}
										variant='textOnly'
										style={{ color: 'black', marginRight: '6px', textTransform: 'uppercase' }}>
										Back
									</CustomButton>
								)}
								{activeStep !== steps.length - 1 && (
									<CustomButton
										id={`btn-next-${activeStep + 1}`}
										variant='contained'
										color='primary'
										onClick={handleNext}
										style={{ background: '#cf362a', textTransform: 'uppercase' }}>
										{activeStep === steps.length - 1 ? 'Finish' : 'Next'}
									</CustomButton>
								)}
							</div>
						</div>
					)}
				</div>
			</Grid>
			<Footer />
			{/* <div id="footer_frame" class="f_iframe">
        <iframe
          id="f_iframe_window"
          src="https://professionalmovingcompany.nl/footer-page"
          frameborder="0"
          scrolling="no"
          height={matchesSm ? 700 : 1700}
          // onLoad={iframeContent()}
        ></iframe>
      </div> */}

			{/* ********************************Snackbar ********************************* */}
			<Snackbar
				open={openSanckbar.open}
				autoHideDuration={6000}
				TransitionComponent={Fade}
				onClose={() => {
					setOpenSnackbar({
						open: false,
						message: '',
						severity: openSanckbar.severity,
					});
				}}>
				<Alert
					onClose={() => {
						setOpenSnackbar({
							open: false,
							message: '',
							severity: openSanckbar.severity,
						});
					}}
					severity={openSanckbar.severity}>
					{openSanckbar.message}
				</Alert>
			</Snackbar>
		</Grid>
	);
}
export default CalculateVolume;
